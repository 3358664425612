import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { RESPOND_ICONS } from "../../constants";
import SVGIcons from "../../components/svgIcons/svgIcons";
/**
 * Respond Partial
 *
 * @description
 * Renders a partial window with response details as map overlay
 */
function RespondPartial({ ResponseStore: { selectedResponse }, parentPage }) {
    return selectedResponse.id ? <div id="map-response" className={"map-response fadeIn theme-light"}>
        <div className="container py-2">
            <div className="row align-items-center mb-2">
                <div className="col text-left"><h3 className="mb-0">Reactie</h3></div>
                <div className="col  text-right">
                    <Link className="btn-close" to={parentPage}>&times;</Link>
                </div>
            </div>

            {selectedResponse.id ? <div className="map-response-item">

                <h5 className="head mb-0">
                    {selectedResponse.name}
                    {selectedResponse.involvement &&
                        " (" + selectedResponse.involvement.name + ")"
                    }
                </h5>
                <div className="row">
                    <div className="col-11">
                        <div className="card mb-0">
                            <div className="card-body card-bubble-left bg-primary">
                                <blockquote className="blockquote mb-0 font-italic">
                                    <SVGIcons className="map-response-item-icon" name={RESPOND_ICONS[selectedResponse.mood]} width={40} fill="currentColor" />

                                    {selectedResponse.text}
                                </blockquote>
                            </div>
                            {selectedResponse.has_photo && <div className="card-footer bg-secondary">
                                <div className="d-flex align-items-top">
                                    <FontAwesomeIcon inverse icon={faImage} className="mr-1" />
                                    <small>Vanwege privacy redenen wordt de foto niet weergegeven</small>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div> : "Deze reactie is verwijderd of bestaat niet"}
            {selectedResponse.client_response &&
                <div className="map-response-item">
                    <h5 className="head  mb-0 text-right">{selectedResponse.client_name}</h5>
                    <div className="row">
                        <div className="col-11 offset-1">
                            <div className="card">
                                <div className="card-body card-bubble-right bg-secondary">
                                    <blockquote className="blockquote mb-0 font-italic" dangerouslySetInnerHTML={{ __html: selectedResponse.client_response }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    </div> : null;
}

RespondPartial.propTypes = {
    ResponseStore: PropTypes.shape({
        /**
         * The selected response
         */
        selectedResponse: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            name: PropTypes.string,
            text: PropTypes.string,
            has_photo: PropTypes.bool,
            mood: PropTypes.string,
            client_name: PropTypes.string,
            client_response: PropTypes.string,
            involvement: PropTypes.shape({
                name: PropTypes.string
            }),
        })
    }),
    parentPage: PropTypes.string
};

export default inject('ResponseStore')(observer(RespondPartial));