import React, { useState } from 'react';
import Control from 'react-leaflet-control';
import { Popup, withLeaflet } from "react-leaflet";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import CursorLabel from "./CursorLabel/CursorLabel";
import RespondMarker from "./RespondMarker/RespondMarker";
import RespondModal from "../RespondModal/RespondModal";
import SimpleModal from '../SimpleModal/SimpleModal';
import RespondButton from '../RespondButton/RespondButton';

/**
 * global variable for opening the modal
 */
let openModal;

/**
 * Respond overlay component to handle the responding interactions on the map
 */
function RespondOverlay(
    {
        link,
        base_layer,
        respond_form,
        leaflet: { map },
        ResponseStore: {
            setResponsePosition,
            setResponseLayer,
            clearResponse,
            setRespondForm,
            toggleResponding,
            isResponding,
            response: { position }
        },
        selectedLayer,
        toggleMapElements
    }) {

    const handleMapClick = (clickEvent) => {
        setResponsePosition(clickEvent.latlng);
        setRespondForm(respond_form)
    };

    const startResponding = (next) => {
        toggleMapElements(true);
        toggleResponding();
        map.on('click', handleMapClick);

        openModal = next;
    };

    const stopResponding = (next) => {
        toggleMapElements(false);
        toggleResponding();
        setResponsePosition(null);
        next && next();
    };

    setResponseLayer(selectedLayer);

    const [inActiveModal, setInactiveModal] = useState(false);

    return (

        <>
            {isResponding && !position && <CursorLabel map={map} />}
            {isResponding && position &&
                <RespondMarker position={position}>
                    <Popup closeButton={false}>
                        <label className="mb-0" onClick={openModal}>Bevestig deze positie en geef reactie</label>
                    </Popup>
                </RespondMarker>
            }
            <Control tag="div" position="bottomleft" className="d-flex align-items-stretch btn-group order-first">
                {respond_form.is_active ? <RespondModal
                    beforeOpen={startResponding}
                    beforeClose={stopResponding}
                    {...respond_form}
                    center={position}
                    base_layer={base_layer}
                    link={link}
                /> : <>
                        <RespondButton onClick={() => setInactiveModal(true)} >{respond_form.open_button_text}</RespondButton>
                        <SimpleModal
                            closeModal={() => setInactiveModal(false)}
                            open={inActiveModal}
                            title={respond_form.inactive_title}
                        >{respond_form.inactive_text}</SimpleModal>
                    </>
                }
            </Control>
        </>
    );
}

RespondOverlay.propTypes = {
    link: PropTypes.string,
    base_layer: PropTypes.string,
    respond_form: PropTypes.object,
    toggleMapElements: PropTypes.func,
    leaflet: PropTypes.shape({
        map: PropTypes.object
    }),
    ResponseStore: PropTypes.shape({
        isResponding: PropTypes.bool,
        setResponseLayer: PropTypes.func,
        clearResponse: PropTypes.func,
        setRespondForm: PropTypes.func,
        setResponsePosition: PropTypes.func,
        toggleResponding: PropTypes.func,
        response: PropTypes.shape({
            position: PropTypes.object
        })
    }),
    selectedLayer: PropTypes.object
};

export default withLeaflet(inject("ResponseStore")(observer(RespondOverlay)));