import React, {Component} from "react";
import PropTypes from "prop-types";
import {inject, observer} from "mobx-react";
import {Marker} from "react-leaflet";
import { coordsToLatLng } from "utils/geoHelpers";
import MarkerClusterGroup from 'react-leaflet-markercluster';
import MapIcon from "../MapIcon/MapIcon";
import SVGIcons from "../svgIcons/svgIcons";
import ClusterIcon from "../ClusterIcon/ClusterIcon";


const icons = {
    suggestie: 'bulb',
    zorg: 'bubble',
    vraag: 'questionmark'
}


/**
 * Response layer container component
 *
 * @description
 * Renders the response markers in a markercluster
 */
class ResponseLayer extends Component {
    static propTypes = {
        /**
         * The selected map layer for filtering responses
         */
        selectedLayerId : PropTypes.number,
        /**
         * Response store
         */
        ResponseStore: PropTypes.shape({
            /**
             * Loading state
             */
            loading: PropTypes.bool,
            /**
             * Array of responses
             */
            responses: PropTypes.objectOf(PropTypes.shape({
                /**
                 * Id of the response
                 */
                id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
                /**
                 * Response geographic location
                 */
                location: PropTypes.shape({
                    /**
                     * Coordinates of the response
                     */
                    coordinates: PropTypes.array
                }),
            })),
            /**
             * Responding state
             */
            isResponding: PropTypes.bool,
            /**
             * The selected response
             */
            selectedResponse: PropTypes.shape({
                /**
                 * id of the selected response
                 */
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            }),
            /**
             * Function to load all responses
             */
            getAllResponses: PropTypes.func,
        }),
        history: PropTypes.shape({
            push: PropTypes.func
        }),
        match: PropTypes.shape({
            path: PropTypes.string
        })
    };

    componentDidMount() {
        this.props.ResponseStore.getAllResponses();
    }

    openComment = (id) => {
        const {ResponseStore: {selectedResponse, isResponding}, history, match} = this.props;
        if (isResponding) return;

        const url = selectedResponse.id !== id ? `/response/${id}` : '';
        history.push(`${match.path}${url}`);
    };

    render() {
        const {ResponseStore: {loading, responses, selectedResponse}, selectedLayerId} = this.props;

        return <MarkerClusterGroup
            spiderfyOnMaxZoom={true}
            zoomToBoundsOnClick={true}
            showCoverageOnHover={true}
            iconCreateFunction={(cluster) => MapIcon(<ClusterIcon content={cluster.getChildCount()}/>)}
        >


            {!loading && responses.filter(response => response.layer_id === selectedLayerId && response.poi === null).map(response => <Marker
                icon={MapIcon(<SVGIcons className={`map-response-icon map-response-${response.mood} ${response.id === selectedResponse.id ? 'active' : ''}`} name={icons[response.mood]} width={40} height={40} fill="currentColor"/>)}
                onClick={() => this.openComment(response.id)}
                key={response.id}
                active={response.id === selectedResponse.id}
                position={coordsToLatLng(response.location.coordinates)}
            />)}
          </MarkerClusterGroup>;
    }
}

export default inject('ResponseStore')(observer(ResponseLayer));