import React, { Component } from 'react';
import { LayersControl, withLeaflet } from "react-leaflet";
import PropTypes from "prop-types";
import BaseMapLayer from "../../MapLayers/BaseMapLayer/BaseMapLayer";

const { BaseLayer } = LayersControl;

const layerEnum = {
    "Basiskaarten/Canvas": {
        url: 'https://services.arcgisonline.nl/arcgis/rest/services/Basiskaarten/Canvas/MapServer',
        img: "/img/lightgray.jpg",
        enabled: 'layer_lightgrey'
    },
    "Basiskaarten/Donkergrijze_Canvas": {
        url: 'https://services.arcgisonline.nl/arcgis/rest/services/Basiskaarten/Donkergrijze_Canvas/MapServer',
        img: "/img/darkgray.jpg",
        enabled: 'layer_darkgrey'
    },
    "Basiskaarten/Stratenkaart": {
        url: 'https://services.arcgisonline.nl/arcgis/rest/services/Basiskaarten/Stratenkaart/MapServer',
        img: "/img/street.png",
        enabled: 'layer_street'
    },
    "Basiskaarten/Topo": {
        url: 'https://services.arcgisonline.nl/arcgis/rest/services/Basiskaarten/Topo/MapServer',
        img: "/img/topographic.jpg",
        enabled: 'layer_topo'
    },
    "Luchtfoto/Luchtfoto": {
        url: 'https://services.arcgisonline.nl/arcgis/rest/services/Luchtfoto/Luchtfoto/MapServer',
        img: "/img/satellite.png",
        enabled: 'layer_satellite'
    },
}

/**
 * Renders the background layer control element
 *
 * @class BackgroundControl
 * @extends {Component}
 */
class BackgroundControl extends Component {
    static propTypes = {
        /**
         * Leaflet object
         */
        leaflet: PropTypes.shape({
            map: PropTypes.object
        }),
        /**
         * Base layer name
         */
        base_layer: PropTypes.string,
        layer_lightgrey: PropTypes.bool,
        layer_darkgrey: PropTypes.bool,
        layer_street: PropTypes.bool,
        layer_topo: PropTypes.bool,
        layer_satellite: PropTypes.bool
    };

    layersToggle = document.getElementsByClassName('leaflet-control-layers-toggle');

    componentDidMount() {

        // wait for dom element to load before setting default image
        setTimeout(() => {
            this.setLayer(this.props.base_layer)
        }, 0)
        this.props.leaflet.map.on("baselayerchange", (layer) => {
            const layerName = layer.name.match(/title=(\w*\/\w*)/i)[1];
            return this.setLayer(layerName)
        })
    }

    setLayer = (layerName) => {

        this.layersToggle[0].setAttribute('style', this.getStyleFromLayerName(layerName))
    }

    getStyleFromLayerName = (layerName) => {
        return `background-image: url(${process.env.PUBLIC_URL}${layerEnum[layerName].img})`
    }

    componentWillUnmount() {
        this.props.leaflet.map.off("baselayerchange")
    }

    render() {
        const { base_layer } = this.props;
        const layers = [
            this.props.layer_lightgrey,
            this.props.layer_darkgrey,
            this.props.layer_street,
            this.props.layer_topo,
            this.props.layer_satellite
        ];


        return (
            <LayersControl collapsed={true} position="topright">
                {Object.keys(layerEnum).map((name, index) => {
                    const layer = layerEnum[name];
                    // eslint-disable-next-line eqeqeq
                    if (layers[index] == true) {
                        return (
                            <BaseLayer
                                key={name}
                                checked={base_layer === layer.url ? true : false}
                                name={`<span title=${name} style='background-image: url(${process.env.PUBLIC_URL}${layer.img})' data-layer='lightgrey' class='leaflet-control-layers-selector-option'/>`}>
                                <BaseMapLayer url={layer.url} />
                            </BaseLayer>
                        )
                    }
                    return null;
                })}
            </LayersControl>
        );

    }
}

export default withLeaflet(BackgroundControl);