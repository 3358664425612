import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { Row, Col, Container } from 'reactstrap';
import { observer, inject, PropTypes as MobxPropTypes } from "mobx-react";
import { coordsToLatLng } from "utils/geoHelpers";
import withPage from "../../components/withPage/withPage";
import CarouselBlock from "../../components/Blocks/Carousel/Carousel";
import VoteModal from "../../components/VoteModal/VoteModal";
import RespondModal from "../../components/RespondModal/RespondModal";
import Enquete from "../../components/Enquete/Enquete";
import ResponseList from "../../components/ResponseList/ResponseList";

class POIPage extends Component {
    static propTypes = {
        PageStore: PropTypes.shape({
            loadingEnquete: PropTypes.bool,
            getPathWithParentById: PropTypes.func,
            enqueteForm: PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
            }),
            details: PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                title: PropTypes.string,
                meta: PropTypes.shape({
                    parent: PropTypes.object
                }),
                text: PropTypes.string,
                image_gallery: PropTypes.object,
                vote_form: PropTypes.object,
                respond_form: PropTypes.object,
                location: PropTypes.shape({
                    coordinates: PropTypes.object
                })
            }),
            getEnqueteFormSubPage: PropTypes.func
        }),
        ResponseStore: PropTypes.shape({
            responses: MobxPropTypes.observableArray,
            setResponsePoi: PropTypes.func,
            setRespondForm: PropTypes.func,
            getAllResponses: PropTypes.func
        }),
        history: PropTypes.shape({
            push: PropTypes.func
        })
    };

    goBack = () => {
        const parentRoute = this.props.PageStore.getPathWithParentById(this.props.PageStore.details.meta.parent.id);
        this.props.history.push(parentRoute)
    };

    beforeOpen = (next) => {
        this.props.ResponseStore.setResponsePoi(
            this.props.PageStore.details.id,
            coordsToLatLng(this.props.PageStore.details.location.coordinates)
        );
        this.props.ResponseStore.setRespondForm(
            this.props.PageStore.details.respond_form
        );
        next();
    }

    componentDidMount() {
        this.props.PageStore.getEnqueteFormSubPage(this.props.PageStore.details.id);
        this.props.ResponseStore.getAllResponses();
    }

    render() {
        const { PageStore: { details, loadingEnquete, enqueteForm }, ResponseStore: { responses } } = this.props;
        const poiResponses = responses.filter((response) => response.poi === details.id);
        return (
            <main className="poi-page">
                <Container>
                    <Row className="align-items-center mb-4">
                        <Col className="flex-grow-1">
                            <h1 className="mb-0">{details.title}</h1>
                        </Col>
                        <Col className="col-auto">
                            <button className="btn btn-close" onClick={() => this.goBack()}>&times;</button>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} className="poi-page-text">
                            <div dangerouslySetInnerHTML={{ __html: details.text }} />
                        </Col>
                        <Col lg={8} className="mb-4">
                            {details.image_gallery &&
                                <div className="mb-4">
                                    <CarouselBlock key={1} items={details.image_gallery} />
                                </div>
                            }
                            {details.vote_form && <VoteModal poiId={details.id} voteOptions={details.image_gallery} {...details.vote_form} />}
                            {details.respond_form && <RespondModal
                                {...details.respond_form}
                                center={coordsToLatLng(this.props.PageStore.details.location.coordinates)}
                                beforeOpen={this.beforeOpen}
                                page={details.id}
                            />}
                            {!loadingEnquete && enqueteForm.id && <Enquete {...enqueteForm} />}

                            {poiResponses.length > 0 && <ResponseList responses={poiResponses} />}
                        </Col>
                    </Row>
                </Container>
            </main>
        );
    }
}

export default withRouter(withPage(inject('ResponseStore')(observer(POIPage))))