import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from "prop-types";

const SimpleModal = ({ open, closeModal, title, children }) => {
    return <Modal size="sm" isOpen={open} toggle={closeModal}>
        <ModalHeader>
            {title}
        </ModalHeader>
        <ModalBody dangerouslySetInnerHTML={{ __html: children }} />
        <ModalFooter>
            <Button color="success" onClick={closeModal}>OK</Button>
        </ModalFooter>
    </Modal>;
};

SimpleModal.propTypes = {
    closeModal: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.string
};

export default SimpleModal;