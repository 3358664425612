import * as ELG from 'esri-leaflet-geocoder';
import 'esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css';
import { MapControl, withLeaflet } from 'react-leaflet';
import PropTypes from "prop-types";

class GeoSearch extends MapControl {
    static propTypes = {
        options: PropTypes.object, // Locate Options
        startDirectly: PropTypes.bool // Instantly start the locate control
    }

    createLeafletElement(props) {
        const map = props.leaflet.map;
        const searchControl = new ELG.Geosearch({
            position: "topright",
            useMapBounds: false,
            searchBounds: props.bounds,
            title: "Zoek locatie",
            placeholder: "Zoek een plaats of adres"
        }).addTo(map);
        return searchControl;
    }
}

export default withLeaflet(GeoSearch);