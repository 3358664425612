import React from 'react';
import PropTypes from "prop-types";
import { withLeaflet } from "react-leaflet";
import { ButtonGroup } from "reactstrap";
import { PropTypes as MobxPropTypes } from "mobx-react";
import { sortByOrder } from 'utils/sortHelpers';
import { LayerSelectorButton } from './LayerSelectorButton/LayerSelectorButton';

function LayerSelector({ layers, selectedLayer, setSelectedLayer, vertical, maxBounds, leaflet }) {


    const resetBoundsAndSelectLayer = (layer) => {
        leaflet.map.fitBounds(maxBounds);
        setSelectedLayer(layer);
    }

    const buttons = layers ? layers.sort(sortByOrder).map(layer =>
        <LayerSelectorButton key={layer.meta.type + layer.id} item={layer} selectedItem={selectedLayer} setSelectedItem={resetBoundsAndSelectLayer} />
    ) : null;

    return (
        <ButtonGroup className="map-layers-selector" vertical={vertical}>
            {buttons}
        </ButtonGroup>
    );
}


LayerSelector.propTypes = {
    layers: MobxPropTypes.observableArray,
    setSelectedLayer: PropTypes.func,
    selectedLayer: MobxPropTypes.observableObject,
    vertical: PropTypes.bool,
    maxBounds: PropTypes.object,
    leaflet: PropTypes.object
};

export default withLeaflet(LayerSelector);
