import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { PropTypes as MobxPropTypes } from "mobx-react";
import Tour from 'reactour'
import Control from 'react-leaflet-control';
import SVGIcons from "components/svgIcons/svgIcons";
import { Button } from 'reactstrap';
import { withLeaflet } from 'react-leaflet';

function IntroTour({ id, showOnce, intro, introduction_slides, button_text, autoplay }) {
    const [isTourOpen, setTourOpen] = useState(false);

    useEffect(() => {
        // eslint-disable-next-line eqeqeq
        if ((localStorage.getItem('tour' + id) === null || !showOnce) && autoplay) {
            setTourOpen(true);
            localStorage.setItem('tour' + id, true);
        }
    }, [id, showOnce, autoplay]);

    const accentColor = getComputedStyle(document.documentElement).getPropertyValue('--primary');

    const tourConfig = [
        {
            content: function DemoHelperComponent() {
                return (
                    <div dangerouslySetInnerHTML={{ __html: intro }} />
                )
            }
        }
    ];


    introduction_slides.map((item) => {

        var step = {};

        step.selector = item.dom_element;
        step.content = function DemoHelperComponent() {
            return (
                <div dangerouslySetInnerHTML={{ __html: item.text }} />
            )
        };
        return tourConfig.push(step);

    });

    return (
        <>
            <Tour
                isOpen={isTourOpen}
                startAt={0}
                onRequestClose={() => setTourOpen(!isTourOpen)}
                steps={tourConfig}
                className="react-tour"
                accentColor={accentColor}
            />
            {button_text &&
                <Control position="bottomleft" className="d-flex align-items-stretch btn-group">
                    <Button color="primary" tag="label" className="btn-no-icon text-nowrap text-center mb-0" onClick={() => setTourOpen(true)}>
                        <SVGIcons className=" d-block mx-auto" name="questionmark" width={32} height={32} fill="currentColor" />
                        <span>{button_text}</span>
                    </Button>
                </Control>
            }
        </>
    )
}

IntroTour.propTypes = {
    id: PropTypes.number,
    showOnce: PropTypes.bool,
    intro: PropTypes.string,
    introduction_slides: MobxPropTypes.observableArrayOf(PropTypes.shape({
        dom_element: PropTypes.string,
        text: PropTypes.string
    })),
    button_text: PropTypes.string,
    autoplay: PropTypes.bool
};

export default withLeaflet(IntroTour);