import React, {Component} from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPoll} from "@fortawesome/free-solid-svg-icons";
import {inject, observer} from "mobx-react";
import {Button, Col, Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import VoteForm from "./VoteForm/VoteForm";
import SimpleModal from "../SimpleModal/SimpleModal";

class VoteModal extends Component {
    static propTypes = {
        beforeOpen: PropTypes.func,
        beforeClose: PropTypes.func,
        open_button_text: PropTypes.string,
        title: PropTypes.string,
        explain_block_title: PropTypes.string,
        explain_block_text: PropTypes.string,
        thank_you_title: PropTypes.string,
        thank_you_text: PropTypes.string,
        poiId: PropTypes.number,
        VoteStore: PropTypes.object
    };

    static defaultProps = {
        beforeOpen: (next) => next(),
        beforeClose: (next) => next()
    };

    state = {
        open: false,
        success: false,
    };

    componentDidMount() {
        this.props.VoteStore.vote.poi = this.props.poiId;
    }

    closeModal = (success = false) => {
        this.props.beforeClose(() => {
            this.setState({
                open: false,
                success: success
            });
        });
    };

    openModal = () => {
        return this.props.beforeOpen(() => this.setState({open: true}));
    };

    render() {
        const closeBtn = <span className="btn-close" onClick={() => this.closeModal()}>&times;</span>;
        const {
            beforeOpen,
            beforeClose,
            open_button_text,
            title,
            explain_block_title,
            explain_block_text,
            thank_you_title,
            thank_you_text,
            VoteStore,
            ...vote_form
        } = this.props;
        return (
            <>
                <Button className="btn-lg btn-no-icon mb-4" color="primary" onClick={this.openModal}>
                    <FontAwesomeIcon className="mr-2" icon={faPoll}/>
                    {open_button_text}
                </Button>
                <Modal className="map-response-form" size="lg" isOpen={this.state.open}
                       toggle={() => this.closeModal()}>
                    <ModalHeader toggle={() => this.closeModal()} close={closeBtn}>{title}</ModalHeader>
                    <Row noGutters>

                        <Col xs={12} sm={5} md={6}>
                            <ModalBody>
                                <h4>{explain_block_title}</h4>
                                <div dangerouslySetInnerHTML={{__html: explain_block_text}}/>
                            </ModalBody>
                        </Col>
                        <Col xs={12} sm={7} md={6}>
                            <ModalBody>
                                <VoteForm closeModal={this.closeModal}
                                          VoteStore={VoteStore} {...vote_form} />
                            </ModalBody>
                        </Col>
                    </Row>
                </Modal>
                <SimpleModal
                    closeModal={() => this.setState({success: false})}
                    open={this.state.success}
                    title={thank_you_title}
                >{thank_you_text}</SimpleModal>
            </>
        )
    }
}

export default inject('VoteStore')(observer(VoteModal));