import React from "react";
import PropTypes from "prop-types";
import SVGIcons from "../svgIcons/svgIcons";
import { RESPOND_ICONS } from "../../constants";

function ResponseItem({ id, name, client_name, client_response, involvement, mood, text }) {
    return (
        <>
            {id ?
                <div className="map-response-item" id={`repsonse_${id}`}>
                    <div className="row">
                        <div className="col-12">
                            <h5 className="mb-0">
                                {name}
                                {involvement.name &&
                                    " (" + involvement.name + ")"
                                }
                            </h5>
                            <div className="card mb-0">
                                <div className="card-body card-bubble-left bg-primary  text-white">
                                    <blockquote className="blockquote mb-0">

                                        <SVGIcons className="map-response-item-icon" name={RESPOND_ICONS[mood]} width={40} fill="currentColor" />
                                        <p className="mb-0  font-italic">
                                            {text}
                                        </p>

                                        {client_response &&
                                            <div className="map-response-item mb-0">
                                                <h6 className="head  mb-0 text-right">{client_name}</h6>
                                                <div className="card">
                                                    <div className="card-body card-bubble-right bg-secondary text-white p-2">
                                                        <blockquote className="blockquote mb-0 font-italic" dangerouslySetInnerHTML={{ __html: client_response }} />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : "Deze reactie is verwijderd of bestaat niet"}

        </>
    )

}

ResponseItem.propTypes = {
    id: PropTypes.number,
    client_name: PropTypes.string,
    name: PropTypes.string,
    involvement: PropTypes.shape({
        name: PropTypes.string
    }),
    mood: PropTypes.string,
    text: PropTypes.string,
    client_response: PropTypes.string
}

export default ResponseItem;