import React from 'react';
import PropTypes from 'prop-types';

const ClusterIcon = (props) => <span>
    <span className="comment-shadow" />
    <span className="cluster">{props.content}</span>
</span>;

ClusterIcon.propTypes = {
    content: PropTypes.oneOfType([PropTypes.string,PropTypes.number])
};

export default ClusterIcon;