import { Component } from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { withLeaflet } from "react-leaflet";
import { coordsToLatLng } from "utils/geoHelpers";
/**
 * Respond Partial
 * 
 * @description
 * Renders a partial window with response details as map overlay
 */
class RespondPartialController extends Component {

    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                /**
                 * Respond id from the url
                 */
                respond_id: PropTypes.string
            })
        }),
        ResponseStore: PropTypes.shape({
            /**
             * Gets the response based on the url id
             */
            setSelectedResponseByID: PropTypes.func
        }),
        MapLayerStore: PropTypes.shape({
            setSelectedLayerByID: PropTypes.func
        }),
        leaflet: PropTypes.shape({
            map: PropTypes.shape({
                setView: PropTypes.func,
                getZoom: PropTypes.func
            })
        })
    };

    componentDidMount() {
        this.props.ResponseStore.setSelectedResponseByID(this.props.match.params.respond_id).then(selectedResponse => {
            if (!selectedResponse.id) return;

            this.props.MapLayerStore.setSelectedLayerByID(selectedResponse.layer_id);
            const zoom = Math.max(this.props.leaflet.map.getZoom(), 7); // sets minimum zoom level on first load
            this.panToLocation(selectedResponse.location.coordinates, zoom);
        });
    }

    componentWillUnmount() {
        // Clear the selected response on unmounting
        this.props.ResponseStore.setSelectedResponseByID();
    }

    async componentDidUpdate(prevProps) {
        const { ResponseStore: { setSelectedResponseByID }, match: { params: { respond_id } } } = this.props;
        // Check if a new response is selected
        if (prevProps.match.params.respond_id !== respond_id) {
            let response = await setSelectedResponseByID(this.props.match.params.respond_id);
            this.panToLocation(response.location.coordinates);
        }
    }

    panToLocation = (coordinates, zoom) => {
        this.props.leaflet.map.setView(coordsToLatLng(coordinates), zoom);
    }

    render() {
        return null;
    }
}

export default withLeaflet(inject('ResponseStore', 'MapLayerStore')(observer(RespondPartialController)));