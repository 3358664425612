
import React, { Fragment, useState } from "react"
import PropTypes from "prop-types"
import { PropTypes as MobxPropTypes, inject, observer } from "mobx-react"
import { faBookOpen, faMap } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MerText from "./MerText"
import MerBreadcrumbs from "./MerBreadcrumbs"


/**
 * Renders the Top component of the Mer page
 * Also handles switching of content and maplayers
 *
 * @exports
 */
function MerTree({ nodes, MerStore, MapLayerStore }) {
    const [isChapterSelectionOpen, setChapterSelectionOpen] = useState(false);

    const onEnterViewport = (node) => {
        setNodeContentBasedOnType(node)
    }

    const onLeaveViewport = (object, node) => {
        if (isScrollingToTop(object.currentPosition))
            setNodeContentBasedOnType(MerStore.getPreviousNode(node))
    }

    function isScrollingToTop(currentPosition) {
        return currentPosition === "below"
    }

    function setNodeContentBasedOnType(node) {
        if (node.map_content_block.length) {
            MerStore.clearContent()

            // wagtail foreign keys are always arrays
            const mapContent = node.map_content_block[0];

            if (mapContent.toggle) {
                MapLayerStore.setLayerToggle(mapContent)
            } else {
                MapLayerStore.setSelectedLayer(mapContent)
            }
        } else {
            MapLayerStore.clearSelectedLayer()
            if (node.image_content_block.length) {
                MerStore.setContent(node.image_content_block[0])
            } else if (node.table_content_block.length) {
                MerStore.setContent(node.table_content_block[0])
            } else {
                MerStore.clearContent()
            }
        }

    }

    return <Fragment>
        <input type="checkbox" className="d-none" name="mer-toggleview" id="mer-toggleview" />
        <div className="map-mer-text">
            <div className="map-mer-breadcrumbs p-2">
                <MerBreadcrumbs node={MerStore.activeEffectTree} />
            </div>

            <div className="map-mer-chapters px-2">
                <MerText onEnterViewport={onEnterViewport} onLeaveViewport={onLeaveViewport} nodes={nodes} />
            </div>
            <input type="checkbox" name="chapterselection" id="chapterselection" className="d-none" />
            <div className={`map-mer-chapterselection ${isChapterSelectionOpen && 'open'}`}>
                <div className="map-layers-selector btn-group-vertical w-100">
                    {nodes.map((node, index) =>
                        <Fragment key={index}>
                            <a onClick={() => setChapterSelectionOpen(false)} className='btn-sm w-100 text-left btn-no-icon btn btn-secondary text-truncate' href={"#" + node.meta.slug}>{node.title}</a>
                            {node.children &&
                                node.children.filter(childNode => childNode.type === 'mer.TextNode').map((child, index) =>
                                    <a key={index} className='btn-sm w-100 text-left btn-no-icon btn btn-secondary pl-3 text-truncate' href={`#${node.slug}-${child.meta.slug}`}><small>{child.title}</small></a>
                                )
                            }
                        </Fragment>
                    )}
                </div>
            </div>
            <div className="map-mer-menu leaflet-bottom leaflet-left">
                <div className="d-flex align-items-stretch btn-group ">
                    <button onClick={() => setChapterSelectionOpen(true)} className=" mb-0 btn-no-icon text-nowrap text-center btn btn-primary">
                        <FontAwesomeIcon size="2x" className=" d-block mx-auto" icon={faBookOpen} />
                        <span>Selecteer hoofdstuk</span>
                    </button>
                </div>
                {MapLayerStore.mapLayers.length && <div className="d-flex align-items-stretch btn-group ">
                    <label htmlFor="mer-toggleview" className=" mb-0 btn-no-icon text-nowrap text-center btn btn-secondary">
                        <FontAwesomeIcon size="2x" className=" d-block mx-auto" icon={faMap} />
                        <span>Bekijk kaart</span>
                    </label>
                </div>}
            </div>

        </div>
    </Fragment>
}

MerTree.propTypes = {
    /**
     * List of MER nodes with text and dynamic content
     */
    nodes: PropTypes.oneOfType([MobxPropTypes.observableArray, PropTypes.array]),
    /**
     * Reference to the mobx MER store
     */
    MerStore: PropTypes.shape({
        /**
         * Set active dynamic content based on the left column text
         */
        setContent: PropTypes.func,
        getPreviousNode: PropTypes.func,
        clearContent: PropTypes.func,
        activeEffectTree: PropTypes.object,
    }),
    /**
     * Reference to the mobx Maplayer store
     */
    MapLayerStore: PropTypes.shape({
        /**
         * Selected layer based on left column text
         */
        setSelectedLayer: PropTypes.func,
        /**
         * Clear all layers from the map
         */
        setLayerToggle: PropTypes.func,
        clearSelectedLayer: PropTypes.func,
        mapLayers: PropTypes.any
    })
}

export default inject('MerStore', 'MapLayerStore')(observer(MerTree))
