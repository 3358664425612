import React, { Fragment } from 'react'
import { inject, observer, PropTypes as MobxPropTypes } from "mobx-react";
import PropTypes from "prop-types";
import { Waypoint } from "react-waypoint";
import Blocks from "../../Blocks/Blocks";

function StoryMapLayerText({ MapLayerStore: { mapLayers, setSelectedLayer } }) {

    const onEnterViewport = (index) => {
        setSelectedLayer(mapLayers[index])
    };
    const onLeaveViewport = (index, object) => {
        if (object.currentPosition === 'below') {
            setSelectedLayer(mapLayers[index - 1]);
        } else {
            setSelectedLayer(mapLayers[index + 1])
        }
    };

    return (

        <div className={"storymap-layers"}>
            {mapLayers.length === 1 ?
                <Blocks blocks={mapLayers[0].text} />
                : mapLayers.map((layer, index) => <Fragment key={index}>

                    <Waypoint bottomOffset='60%' onEnter={() => onEnterViewport(index)} onLeave={(object) => onLeaveViewport(index, object)}>
                        <div className="storymap-chapter">
                            <div name={`text${layer.id}`} />
                            <Blocks blocks={layer.text} disableLazyload={true} />
                        </div>
                    </Waypoint>

                    <div className="mobile-spacer"></div>

                </Fragment>
                )}
        </div>


    );
}

StoryMapLayerText.propTypes = {
    MapLayerStore: PropTypes.shape({
        mapLayers: MobxPropTypes.observableArray,
        setSelectedLayer: PropTypes.func,
        selectedLayer: MobxPropTypes.observableObject,
        showSelectedLayer: PropTypes.bool,
        setShowSelectedLayer: PropTypes.func
    })
};

export default inject('MapLayerStore')(observer(StoryMapLayerText));