import React, {useState, useEffect} from "react";
import {FormFeedback, FormGroup, FormText, Input, Label} from "reactstrap";
import PropTypes from "prop-types";

function LimitedTextarea({rows, cols, limit, value, onChange, name, label, error, ...textareaProps}) {
    const [content, setContent] = useState(value);
    const [textCount, setTextCount] = useState(0);

    const setFormattedContent = text => {
        if (text.length > limit) {
            setContent(text.substring(0, limit));
            setTextCount(limit);
        } else {
            setContent(text);
            setTextCount(text.length);
        }
    };

    useEffect(() => {
        onChange(name, content);
        setFormattedContent(content);
    }, [content]);

    return (
        <FormGroup>
            <Label for={name}>{label}</Label>
            <Input
                invalid={!!error}
                type="textarea"
                id={name}
                name={name}
                rows={rows}
                cols={cols}
                onChange={event => setFormattedContent(event.target.value)}
                value={content}
                {...textareaProps}
            />
            {error && <FormFeedback>{error[0]}</FormFeedback>}
            <FormText>Limiet is {limit} karakters, nog over: {limit - textCount}</FormText>
        </FormGroup>
    );
}

LimitedTextarea.propTypes = {
    rows: PropTypes.number,
    cols: PropTypes.number,
    limit: PropTypes.number,
    value: PropTypes.string,
    onChange: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.object
}

export default LimitedTextarea;