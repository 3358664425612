import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Container } from "reactstrap";
import { withRouter } from "react-router";

function SearchForm({ isOpen, searchString, onSearch, history }) {
    const [search, changeSearch] = useState(searchString)
    function submitForm(submitEvent) {
        submitEvent.preventDefault();
        history.push('/search/' + search)
        onSearch(search)
    };

    return (
        <div id="site-search" className={isOpen ? 'open' : ''}>
            <form onSubmit={submitForm}>
                <Container >
                    <Row>
                        <Col tag="label" htmlFor="searchfield" className="flex-grow-1 mb-0">
                            <input
                                id="searchfield"
                                ref={input => isOpen && input && input.focus()}
                                autoFocus
                                type="search"
                                className="form-control"
                                placeholder="Zoekterm"
                                value={search} onChange={(e) => changeSearch(e.target.value)}
                            />
                        </Col>
                        <Col className="col-auto site-search-button">
                            <button type="submit" className="btn btn-primary">Zoeken</button>
                        </Col>
                    </Row>
                </Container>
            </form>
        </div>
    )
}

SearchForm.propTypes = {
    isOpen: PropTypes.bool,
    searchString: PropTypes.string,
    onSearch: PropTypes.func,
    history: PropTypes.shape({
        push: PropTypes.func
    })
}

export default withRouter(SearchForm);