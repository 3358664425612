import React from "react"
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react"
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";

function EffectFilter({ MerStore: { filterEffectString, filterEffectTree } }) {
    return <InputGroup>
        <InputGroupAddon addonType="prepend"><InputGroupText><FontAwesomeIcon icon={faFilter} /></InputGroupText></InputGroupAddon>
        <Input
            autoFocus
            name="q"
            type="search"
            placeholder="Bijv. Natura 2000"
            value={filterEffectString}
            onChange={(e) => filterEffectTree(e.target.value)}
        />
    </InputGroup>
}

EffectFilter.propTypes = {
    MerStore: PropTypes.shape({
        filterEffectString: PropTypes.string,
        filterEffectTree: PropTypes.func
    })
}

export default inject("MerStore")(observer(EffectFilter))
