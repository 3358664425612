import React, { Component } from "react";
import { Button, Form, FormGroup, Col, FormFeedback, Row, Label, Input, FormText } from "reactstrap";

import { inject, observer, PropTypes as MobxPropTypes } from "mobx-react";
import PropTypes from "prop-types";
import FieldGroup from "../../FieldGroup/FieldGroup";
import LimitedTextarea from "../../LimitedTextarea/LimitedTextarea";
import SVGIcons from "../../svgIcons/svgIcons";
import Checkbox from "../../Checkbox/Checkbox";
import CommentRadio from "./CommentRadio";
import { sortByOrder } from "../../../utils/sortHelpers";
import { RESPOND_ICONS } from "../../../constants";

class RespondForm extends Component {
    static propTypes = {
        allow_photo_upload: PropTypes.bool,
        submit_button_text: PropTypes.string,
        cancel_button_text: PropTypes.string,
        moods: MobxPropTypes.observableArray,
        involvements: PropTypes.object,
        themes: PropTypes.object,
        privacy_statement: PropTypes.shape({
            file: PropTypes.string
        }),
        ResponseStore: PropTypes.shape({
            response: PropTypes.object,
            error: PropTypes.object,
            success: PropTypes.bool,
            updateProperty: PropTypes.func,
            addResponse: PropTypes.func
        }),
        closeModal: PropTypes.func
    };

    state = {
        btnDisabled: false
    };

    submitForm = async (e) => {
        e.preventDefault();

        this.setState({ btnDisabled: true });
        let response = await this.props.ResponseStore.addResponse();

        if (this.props.ResponseStore.success) {
            this.props.closeModal(true, response.id);
            this.setState({ btnDisabled: false });
        }
        else {
            this.setState({ btnDisabled: false });
        }
    };

    render() {
        const {
            allow_photo_upload,
            submit_button_text,
            cancel_button_text,
            moods,
            involvements,
            themes,
            closeModal,
            privacy_statement,
            ResponseStore: {
                response,
                updateProperty,
                error
            }
        } = this.props;

        function onChange(name, value) {
            updateProperty(name, value);
        }

        function onChangeSelect(name, value) {
            updateProperty(name, {
                value: value
            });
        }

        function changeFile() {
            var imagefile = document.querySelector('#photo');

            if (imagefile.files[0]) {
                document.getElementById("filename").innerHTML = imagefile.files[0].name;
            }

            updateProperty('photo', imagefile.files[0]);
        }

        let moodString;

        if (moods.length === 1) {
            onChange('mood', moods[0])
        } else {
            // eslint-disable-next-line no-console
            const moodsCopy = moods.toJS();
            moodString = `Heeft u een ${moodsCopy.slice(0, -1).join(', ')} of ${moodsCopy.slice(-1)}?`;
        }

        return (
            <Form onSubmit={this.submitForm}>
                {moods.length > 1 && <>
                    <Row>
                        <Col xs={12}>
                            {moodString}
                        </Col>
                    </Row>
                    <FormGroup row className="no-gutters">
                        {moods.map((mood, index) => <Col key={mood}>
                            <CommentRadio
                                label={mood}
                                name="mood"
                                error={error.mood}
                                value={mood}
                                onChange={onChange}
                            >
                                <SVGIcons className={`map-response-icon map-response-${mood} mr-1`} name={RESPOND_ICONS[mood]} width={40} height={40}
                                    fill="currentColor" />
                                {mood}
                                {error.mood && index === 0 && <FormFeedback>{error.mood[0]}</FormFeedback>}
                            </CommentRadio>
                        </Col>)}
                    </FormGroup>
                </>}

                <FieldGroup
                    name="theme"
                    label={`Waarover gaat uw ${response.mood ? response.mood : 'reactie'}?`}
                    value={response.theme.value}
                    type="select"
                    onChange={onChangeSelect}
                    error={error.theme}
                >
                    <option value="" disabled hidden>Selecteer een thema</option>
                    {themes.sort(sortByOrder).map(theme => <option
                        key={theme.value}
                        value={theme.value}>
                        {theme.name}
                    </option>)}
                </FieldGroup>

                <LimitedTextarea
                    limit={500}
                    value={response.text}
                    name="text"
                    label="Wat wilt u met ons delen?"
                    placeholder="Uw reactie"
                    onChange={onChange}
                    error={error.text}
                />

                {
                    allow_photo_upload && <FormGroup>
                        <Label>Wilt u een foto van de situatie toevoegen?</Label>
                        <FormText color="muted">
                            Om privacy redenen zal de foto niet in de reactie worden weergegeven. Wel is de foto zichtbaar voor het projectteam.
                    </FormText>

                        <Label htmlFor="photo" className="btn btn-sm btn-primary mr-1">Upload foto
                        <Input type="file" className="d-none" name="photo" id="photo" accept="image/*" onChange={changeFile} />
                        </Label>
                        <small id="filename"></small>


                        <FormText color="muted">
                            Max. 1 foto uploaden
                    </FormText>
                    </FormGroup>
                }

                <FieldGroup
                    name="involvement"
                    label="Welke van de volgende opties omschrijft uw betrokkenheid het beste?"
                    value={response.involvement.value}
                    type="select"
                    onChange={onChangeSelect}
                    error={error.involvement}
                >
                    <option value="" disabled hidden>Selecteer uw betrokkenheid</option>
                    {involvements.sort(sortByOrder).map(involvement => <option
                        key={involvement.value}
                        value={involvement.value}>
                        {involvement.name}
                    </option>)}
                </FieldGroup>

                <FieldGroup name="name" label={<span>Naam <span className="text-muted">(optioneel)</span></span>} value={response.name} type="text" placeholder="Uw naam"
                    error={error.name}
                    onChange={onChange} />

                <FieldGroup name="email" label="Email adres" value={response.email} type="email"
                    error={error.email}
                    placeholder="Uw email adres" onChange={onChange} />

                <FieldGroup name="zip_code" label="Postcode" value={response.zip_code} type="text"
                    placeholder="Uw postcode"
                    maxLength="7"
                    error={error.zip_code}
                    onChange={onChange} />


                <FormGroup tag="fieldset">
                    <Checkbox error={error.privacy} value={response.privacy} onChange={onChange} name="privacy">
                        Ik ga akkoord met het <a href={privacy_statement.file} rel="noopener noreferrer"
                            target="_blank">privacystatement</a>
                    </Checkbox>
                </FormGroup>

                <FormGroup tag="fieldset">
                    <Checkbox error={error.sendmail} value={response.sendmail} onChange={onChange} name="sendmail">
                        Ik ga akkoord met het versturen van een mail bij het beantwoorden van mijn reactie.
                    </Checkbox>
                </FormGroup>

                <Button className="btn-cancel" color="white"
                    onClick={() => closeModal(false)}>{cancel_button_text}</Button>
                <Button disabled={this.state.btnDisabled} className="float-right" submit="true" color="primary">{submit_button_text}</Button>
            </Form >
        )
    }
}

export default inject('ResponseStore', 'PageStore')(observer(RespondForm));
