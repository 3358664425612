import React from "react";
import Control from "react-leaflet-control";
import { Button } from 'reactstrap';
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function ToggleOptions() {
    return (
        <Control position="bottomleft" className="d-flex align-items-stretch btn-group order-last toggle-options">
            <Button tag="label" htmlFor="toggleMisc" color="primary" className="mb-0 btn-no-icon text-nowrap text-center">
                <FontAwesomeIcon size="2x" className=" d-block mx-auto" icon={faBars} />
                <span>Opties</span>
            </Button>
        </Control>
    )
}

export default ToggleOptions;