import React from 'react';
import PropTypes from 'prop-types';
import {toJS} from "mobx";
import {Marker} from "react-leaflet";
import POIIcon from '../POIIcon/POIIcon'
import MapIcon from "../MapIcon/MapIcon";

const POIMarker = ({geom, onPOIClick, ...props}) => {
    const icon = MapIcon(<POIIcon {...props} />);
    return (
        <Marker position={toJS(geom)} onClick={onPOIClick} icon={icon} {...props} />
    )
};

POIMarker.propTypes = {
    geom: PropTypes.object,
    onPOIClick: PropTypes.func,
};

export default POIMarker;