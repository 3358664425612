import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faPoll, faCircle, faStreetView, faPlusCircle, faMinusCircle,faQuestionCircle,faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

const POIIcon = (props) => (<div className="poi-label">
    <span className="poi-label-content">{props.title}</span>
    {props.comments.length > 0 ? <span className="poi-comment-label">{props.comments}</span> : ''}

    <span className="poi-icon fa-layers fa-fw">
        <FontAwesomeIcon icon={faCircle} color="white" size="2x" />

        {props.poiType === 'faPoll' &&
            <FontAwesomeIcon icon={faPoll} color="#004699" size="2x" transform="shrink-6" />
        }
        {props.poiType === 'faStreetView' &&
            <FontAwesomeIcon icon={faStreetView} color="#004699" size="2x" transform="shrink-6" />
        }
        {props.poiType === 'faCamera' &&
            <FontAwesomeIcon icon={faCamera} color="#004699" size="2x" transform="shrink-6" />
        }
        {props.poiType === 'faQuestionCircle' &&
            <FontAwesomeIcon icon={faQuestionCircle} className="text-success" size="2x" transform="shrink-6" />
        }
        {props.poiType === 'faPlusCircle' &&
            <FontAwesomeIcon icon={faPlusCircle} className="text-success" size="2x" transform="shrink-6" />
        }
        {props.poiType === 'faMinusCircle' &&
            <FontAwesomeIcon icon={faMinusCircle}  className="text-danger" size="2x" transform="shrink-6" />
        }
        {props.poiType === 'faExclamationCircle' &&
            <FontAwesomeIcon icon={faExclamationCircle}  className="text-warning" size="2x" transform="shrink-6" />
        }
    </span>

</div>)
    ;

POIIcon.propTypes = {
    title: PropTypes.string,
    comments: PropTypes.number,
    visualisation: PropTypes.string,
    poiType: PropTypes.oneOf([
        'faPoll',
        'faStreetView',
        'faCamera',
        'faQuestionCircle',
        'faPlusCircle',
        'faMinusCircle',
        'exclamationCircle',
    ])
};

POIIcon.defaultProps = {
    comments: 0,
    visualisation: 'camera'
};

export default POIIcon;