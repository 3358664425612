import React from "react";
import Home from "../../scenes/Home/Home";
import InfoPage from "../../scenes/Info/InfoPage";
import MapPage from "../../scenes/MapPage/MapPage";

import FAQPage from "../../scenes/FAQPage/FAQPage";
import NotFound from "../../components/NotFound/NotFound";
import POIPage from "../../scenes/POIPage/POIPage";
import StoryMapPage from "../../scenes/StoryMapPage/StoryMapPage";
import SearchPage from "../../scenes/SearchPage/SearchPage";
import MerPage from "../../scenes/MERPage/MERPage";

/**
 * Method to return the correct component given a wagtail route
 *
 * @param route object with wagtail route data
 * @returns {Node} Loader or Page component
 */
export const getPageComponentFromType = (route) => {
    let page;

    switch (route.meta.type) {
        case 'home.HomePage':
            page = <Home {...route} />;
            break;
        case 'info.InfoPage':
            page = <InfoPage {...route} />;
            break;
        case 'faq.FaqPage':
            page = <FAQPage {...route} />
            break;
        case 'map.MapPage':
            page = <MapPage {...route} />
            break;
        case 'storymap.StoryMapPage':
            page = <StoryMapPage {...route} />
            break;
        case 'mer.ReportMerPage':
        case 'mer.MapMerPage':
            page = <MerPage {...route} />
            break;
        case 'poi.PoiPage':
            page = <POIPage {...route} />
            break;
        case 'search.SearchPage':
            page = <SearchPage {...route} />
            break;
        default:
            page = <NotFound />;
            break;
    }

    return page;
};