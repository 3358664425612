import React from 'react';
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export function LayerSelectorButton({ item, selectedItem, setSelectedItem }) {
    return <Button className="btn-sm w-100 text-left btn-no-icon text-nowrap" onClick={() => setSelectedItem(item)} active={item === selectedItem}>
        <FontAwesomeIcon className="mr-1 py-0" icon={faLayerGroup} transform="down-4" />
        {item.name || item.title}
    </Button>;
}

LayerSelectorButton.propTypes = {
    item: PropTypes.shape({
        name: PropTypes.string,
        title: PropTypes.string
    }),
    selectedItem: PropTypes.object,
    setSelectedItem: PropTypes.func
}