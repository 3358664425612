import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Col, Container, Row } from "reactstrap";
import { config } from 'utils/envHelpers';
import withPage from "../../components/withPage/withPage";
import CollapseList from "../../components/CollapseList/CollapseList";
import Footer from "../../components/Footer/Footer";

export class FAQPage extends Component {

    fullWidthRows() {
        if (config.blocks.defaults.fullWidthRows === true) {
            return 'container-fluid px-0 ';
        } else {
            return 'container px-0 ';
        }
    }

    render() {
        const { PageStore: { details } } = this.props;

        const faq_items = details.faq_items;

        return (
            <>
                <main>
                    <div className={this.fullWidthRows() + " faq"}>
                        <Row className="no-gutters pt-4">
                            <Col sm={12} className="mb-0 w-100">
                                <Container>
                                    <h1>{details.title}</h1>
                                    {details.intro &&
                                        <div dangerouslySetInnerHTML={{ __html: details.intro }} />
                                    }
                                </Container>
                            </Col>
                        </Row>
                    </div>
                    <div className={this.fullWidthRows() + " faq"}>
                        <Row className="no-gutters pt-4">
                            <Col sm={12} className="mb-4 w-100">
                                <Container>
                                    <div className="panel-group faq border-bottom">
                                        {faq_items && <CollapseList key={details.id} list={faq_items} />}
                                    </div>
                                </Container>
                            </Col>
                        </Row>
                    </div>
                </main>
                <Footer {...details.footer} />
            </>
        );
    }
}

FAQPage.propTypes = {
    PageStore: PropTypes.shape({
        details: PropTypes.object
    })
};

export default withPage(FAQPage)



