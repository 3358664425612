import axios from 'axios'
import Cookies from 'js-cookie';
import commonStore from 'stores/Common/CommonStore';
import authStore from 'stores/Auth/AuthStore';
import { history } from 'App';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

const API_ROOT = "/api";
const config = {
    credentials: 'include',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRFToken': Cookies.get('csrftoken')
    }
};
const configWithFile = {
    credentials: 'include',
    headers: {
        'Content-Type': 'multipart/form-data'
    }
};

axios.interceptors.request.use(function (config) {
    if (commonStore.token && config.url.indexOf(API_ROOT) !== -1) {
        config.headers.Authorization = `Token  ${commonStore.token}`;
    }

    return config;
});

axios.interceptors.response.use(undefined, error => {
    switch (error.response.status) {
        case 401:
            authStore.logout()
            history.push('/login')
            break;
        case 403:
            history.push('/')
            break;
        default:
            break;
    }
    return Promise.reject(error);
});

const requests = {
    get: url => axios.get(`${API_ROOT}${url}`).then(res => res.data),
    post: (url, data) =>
        axios.post(`${API_ROOT}${url}`, data, config).then(res => res.data),
    postWithFile: (url, data) => (
        axios.post(`${API_ROOT}${url}`, data, configWithFile).then(
            res => res.data
        )),
    put: (url, data) =>
        axios.put(`${API_ROOT}${url}`, data, config).then(res => res.data),
    delete: url => axios.delete(`${API_ROOT}${url}`).then(res => res.status)
};

const Auth = {
    login: (data) => requests.post('/auth/', data),
    current: () => requests.get('/user/')
}

const Page = {
    /**
     * Get all pages
     * @return Promise
     */
    all: () => requests.get('/pages/?fields=*&limit=1000'),
    /**
     * Get page details by id
     * @param id Number
     * @return Promise
     */
    byId: (id) => requests.get(`/pages/${id}/`),
    /**
     * Get pois from a map page
     * @param id Number
     * @return Promise
     */
    getPois: (id) => requests.get(`/pages/?type=poi.PoiPage&child_of=${id}&fields=*&limit=1000`),
    /**
     * Get enquete form on a page
     * @param id Number
     * @return Promise
     */
    getEnqueteFormSubPage: (id) => requests.get(`/pages/?type=enquete.EnqueteFormSubPage&child_of=${id}&fields=*&limit=1000`),
    /**
     * Get page metadata by slug
     * @param slug String
     * @return Promise
     */
    getNodes: (id) => requests.get(`/pages/?type=mer.Node&fields=*&descendant_of=${id}&limit=1000`),
    meta: (slug) => requests.get(`/pages/?slug=${slug}&fields=*&limit=1000`),
};

const Search = {
    /**
     * Get searchresults on a page
     * @param search String
     * @return Promise
     */
    getSearchresults: (search) => requests.get(`/pages/?search=${search}&fields=*`),
}

const Response = {
    /**
     * Get all responses
     * @return Promise
     */
    all: () => requests.get(`/response/`),
    add: (data) => requests.postWithFile(`/response/`, data),
    unsubscribe: (token) => requests.get(`/response/unsubscribe/${token}`)
};

const Vote = {
    all: () => requests.get(`/vote/`),
    add: (data) => requests.post(`/vote/`, data)
};

const Enquete = {
    add: (data) => requests.post(`/enquete/`, data)
}

const Layer = {
    getLegend: (mapserver, token) => {
        if (token && token !== "") {
            return axios.get(`${mapserver}/legend?token=${token}&f=pjson`)
        } else {
            return axios.get(`${mapserver}/legend?f=pjson`)
        }
    }

};

const Messages = {
    all: () => requests.get(`/messages/`)
}


export default {
    Auth,
    Page,
    Search,
    Response,
    Vote,
    Enquete,
    Layer,
    Messages
}