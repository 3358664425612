import { withLeaflet, MapControl } from "react-leaflet";
import Locate from "leaflet.locatecontrol";
import PropTypes from "prop-types";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "@fortawesome/fontawesome-free/css/solid.min.css";

class LocateControl extends MapControl {
    static propTypes = {
        startDirectly: PropTypes.bool,
        leaflet: PropTypes.shape({
            map: PropTypes.object
        })
    }

    createLeafletElement(props) {
        const { startDirectly, leaflet: { map } } = props;

        const lc = new Locate({
            position: "topright",
            showPopup: false,
            icon: 'fa fa-crosshairs',
            strings: {
                title: "Ga naar mijn locatie"
                
            },
            keepCurrentZoomLevel: true
        }).addTo(map)

        if (startDirectly)
            setTimeout(() => {
                lc.start()
            }, 1000)

        return lc
    }
}

export default withLeaflet(LocateControl);