import React, { Component } from "react";
import { Marker } from "react-leaflet";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router-dom";
import { RESPOND_ICONS } from "../../constants";
import DefaultMap from "../DefaultMap/DefaultMap";
import RespondForm from "./RespondForm/RespondForm";
import SimpleModal from "../SimpleModal/SimpleModal";
import MapIcon from "../MapIcon/MapIcon";
import SVGIcons from "../svgIcons/svgIcons";
import RespondButton from "../RespondButton/RespondButton";

class RespondModal extends Component {
    static propTypes = {
        link: PropTypes.string,
        beforeOpen: PropTypes.func,
        beforeClose: PropTypes.func,
        open_button_text: PropTypes.string,
        title: PropTypes.string,
        explain_block_title: PropTypes.string,
        explain_block_text: PropTypes.string,
        center: PropTypes.object,
        base_layer: PropTypes.string,
        thank_you_title: PropTypes.string,
        thank_you_text: PropTypes.string,
        page: PropTypes.number,
        page_text: PropTypes.string,
        is_active: PropTypes.bool,
        inactive_title: PropTypes.string,
        inactive_text: PropTypes.string,
        ResponseStore: PropTypes.shape({
            setResponseLayer: PropTypes.func,
            clearResponse: PropTypes.func,
            setResponsePosition: PropTypes.func,
            toggleResponding: PropTypes.func,
            isResponding: PropTypes.bool,
            response: PropTypes.shape({
                position: PropTypes.object,
                mood: PropTypes.object
            })
        }),
        history: PropTypes.shape({
            push: PropTypes.func
        }),
        match: PropTypes.shape({
            path: PropTypes.string
        })
    };

    static defaultProps = {
        beforeOpen: (next) => next(),
        beforeClose: (next) => next()
    };

    state = {
        open: false,
        success: false,
        id: null
    };

    /**
     * Calls before close and clears response form
     *
     * @param {boolean} [success=false]
     * @param {number} [id=null]
     * @memberof RespondModal
     */
    closeModal = (success = false, id = null) => {
        this.props.beforeClose(() => {
            this.setState({
                open: false,
                success: success,
                id: id
            });
        });
        this.props.ResponseStore.clearResponse();
    };

    openModal = () => {
        return this.props.beforeOpen(() => this.setState({ open: true }));
    };

    closeSuccessAndGoToResponse = () => {
        this.setState({ success: false });
        this.props.history.push(`${this.props.match.path}/response/${this.state.id}`);
    }

    cancelResponding = () => {
        this.closeModal();
    }

    render() {

        const closeBtn = <span className="btn-close" onClick={this.cancelResponding}>&times;</span>;

        const {
            beforeOpen,
            beforeClose,
            open_button_text,
            title,
            explain_block_title,
            explain_block_text,
            center,
            base_layer,
            thank_you_title,
            thank_you_text,
            page,
            page_text,
            is_active,
            inactive_title,
            inactive_text,
            ResponseStore,
            ...respond_form
        } = this.props;

        return (
            <>

                {!ResponseStore.isResponding ?
                    <RespondButton onClick={this.openModal} >{open_button_text}</RespondButton>
                    :
                    <Button className="btn-no-icon text-nowrap text-center" color="warning" onClick={this.cancelResponding}>
                        <FontAwesomeIcon size="2x" className=" d-block mx-auto" icon={faTimes} />
                        <span>Annuleer</span>
                    </Button>
                }
                {is_active ? <Modal className="map-response-form" size="lg" isOpen={this.state.open} toggle={this.cancelResponding}>
                    <ModalHeader toggle={this.cancelResponding} close={closeBtn}>{title}</ModalHeader>
                    <Row noGutters>
                        <Col xs={12} sm={7} md={6}>
                            <ModalBody>
                                <RespondForm closeModal={this.closeModal}
                                    ResponseStore={ResponseStore} {...respond_form} />
                            </ModalBody>
                        </Col>
                        <Col xs={12} sm={5} md={6}>
                            {center && <DefaultMap
                                className="modal-map"
                                center={center}
                                zoom={12}
                                zoomControl={false}
                                dragging={false}
                                keyboard={false}
                                scrollWheelZoom={false}
                                doubleClickZoom={false}
                                tap={false}
                                touchZoom={false}
                                base_layer={base_layer}
                            >
                                <Marker icon={MapIcon(<SVGIcons
                                    className={`map-response-icon map-response-${ResponseStore.response.mood || 'default'} active`}
                                    name={RESPOND_ICONS[ResponseStore.response.mood]} width={40} height={40}
                                    fill="currentColor" />)} position={center} />
                            </DefaultMap>}
                            <ModalBody>
                                {page && <div dangerouslySetInnerHTML={{ __html: page_text }} />}
                                {explain_block_title}
                                <div dangerouslySetInnerHTML={{ __html: explain_block_text }} />
                            </ModalBody>
                        </Col>
                    </Row>
                </Modal> : <SimpleModal
                    closeModal={this.cancelResponding}
                    open={this.state.open}
                    title="Reactietermijn gesloten"
                >{inactive_text}</SimpleModal>
                }
                <SimpleModal
                    closeModal={this.closeSuccessAndGoToResponse}
                    open={this.state.success}
                    title={thank_you_title}
                >{thank_you_text}</SimpleModal>
            </>
        )
    }
}

export default withRouter(inject('ResponseStore')(observer(RespondModal)));