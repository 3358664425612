import React from "react";
import { inject, observer, PropTypes as MobxPropTypes } from "mobx-react";
import PropTypes from "prop-types";
import Blocks from "../../Blocks/Blocks";


function LayerText({ MapLayerStore: { selectedLayer, showSelectedLayer, setShowSelectedLayer } }) {
    return (selectedLayer.text && selectedLayer.text.length) ? (
        <div className={"map-layers fadeIn theme-light " + (showSelectedLayer ? ' map-layers-open ' : '')}>
            <div className="w-100 text-right">
                <span className="btn-close" onClick={e => setShowSelectedLayer(false)}>&times;</span>
            </div>
            <Blocks blocks={selectedLayer.text} />
        </div>
    )
        : null;
}

LayerText.propTypes = {
    MapLayerStore: PropTypes.shape({
        mapLayers: MobxPropTypes.observableArray,
        selectedLayer: MobxPropTypes.observableObject,
        showSelectedLayer: PropTypes.bool,
        setShowSelectedLayer: PropTypes.func
    })
};

export default inject('MapLayerStore')(observer(LayerText));