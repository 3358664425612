import React from "react";
import PropTypes from "prop-types";
import Control from 'react-leaflet-control';
import { Button } from 'reactstrap';
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LayerSelector from "../../../components/MapLayers/LayerSelector/LayerSelector";

function MapLayerContainer({ mapLayers, selectedLayer, maxBounds, setSelectedLayer }) {
    return (
        <>
            <Control position="bottomleft" tag="div" className="d-flex align-items-stretch btn-group">
                <Button htmlFor="toggleLayers" tag="label" color="primary" className=" mb-0 btn-no-icon text-nowrap text-center">
                    <FontAwesomeIcon size="2x" className=" d-block mx-auto" icon={faLayerGroup} />
                    <span>{mapLayers.length} Kaartlagen</span>
                </Button>
            </Control>

            <Control position="topleft" tag="div">
                <div id="map-layers-inside" className="map-layers-inside">
                    <div className="map-layers-inside-map">
                        <LayerSelector
                            layers={mapLayers}
                            selectedLayer={selectedLayer}
                            setSelectedLayer={setSelectedLayer}
                            vertical={true}
                            maxBounds={maxBounds}
                        />
                    </div>
                </div>
            </Control>
        </>

    );
}

MapLayerContainer.propTypes = {
    selectedLayer: PropTypes.object,
    maxBounds: PropTypes.object,
    setSelectedLayer: PropTypes.func,
    mapLayers: PropTypes.object
};


export default MapLayerContainer;
