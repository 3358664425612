import React from "react";

const NotFound = () => {
    return (
        <div className="container mt-4">
            <h1>404</h1>
            <h2>Deze pagina is helaas niet InBeeld.</h2>
        </div>
    );
};

export default NotFound;