import React, { Component} from 'react';
import { LatLngBounds } from 'leaflet';
import PropTypes from "prop-types";
import { coordsToLatLng } from "utils/geoHelpers";
import { inject, observer, PropTypes as MobxPropTypes } from "mobx-react";
import withPage from "components/withPage/withPage";
import PageDefaultMap from "components/DefaultMap/PageDefaultMap";
import StoryMapLayerText from "components/MapLayers/LayerText/StoryMapLayerText";
import IntroTour from "components/IntroTour/IntroTour";
import MapLayerContainer from 'components/MapLayers/MapLayerContainer/MapLayerContainer';
import ToggleOptions from 'components/ToggleOptions/ToggleOptions';


/**
 * Map Page
 *
 * @description
 * Renders the map page from wagtail including all components
 */
export class StoryMapPage extends Component {
    static propTypes = {
        /**
         * Page Store
         */
        PageStore: PropTypes.shape({
            /**
             * Map Page details
             */
            details: PropTypes.shape({
                /**
                 * Map center
                 */
                upper_left: PropTypes.shape({
                    /**
                     * Coordinates of the map center
                     */
                    coordinates: PropTypes.object
                }),
                lower_right: PropTypes.shape({
                    /**
                     * Coordinates of the map center
                     */
                    coordinates: PropTypes.object
                }),
                image_map_layers: PropTypes.object,
                esri_map_layers: PropTypes.object,
                groups: MobxPropTypes.observableArray,
                introduction: MobxPropTypes.observableObject,
                introduction_button: PropTypes.string,
                autoplay: PropTypes.bool
            })
        }),
        MapLayerStore: PropTypes.shape({
            setMapLayers: PropTypes.func,
            setGroups: PropTypes.func,
            selectedLayer: PropTypes.object,
            setSelectedGroup: PropTypes.func,
            selectedGroup: PropTypes.object,
            mapLayers: PropTypes.object,
            groups: MobxPropTypes.observableArray
        }),
        /**
         * React router match prop
         */
        match: PropTypes.object,
        /**
         * React router history prop
         */
        history: PropTypes.object,
        meta: PropTypes.shape({
            type: PropTypes.oneOf(['map.MapPage', 'storymap.StoryMapPage']),
        })
    };

    componentDidMount() {
        const { MapLayerStore: { setGroups }, PageStore: { details } } = this.props;
        // TODO check storymaplayer
        setGroups(details.groups);
    }

    setSelectedLayer = (layer) => {
        const { MapLayerStore: { setSelectedGroup }, match, history } = this.props;
        history.push(match.path);
        setSelectedGroup(layer);
    }

    render() {
        const { PageStore: { details }, MapLayerStore: { selectedLayer, selectedGroup, mapLayers, groups } } = this.props;
        //line below is a bit ugly because we didnt manage to get the maplayers in one array from wagtail.
        const maxBounds = new LatLngBounds(coordsToLatLng(details.upper_left.coordinates), coordsToLatLng(details.lower_right.coordinates));
        return (
            <div className="map-page" id="text-overlay">
                <StoryMapLayerText />

                <input className="d-none" type="checkbox" name="toggle-menu-item" id="toggleLayers" defaultChecked={details.visibility_maplayers} />
                <input className="d-none" type="checkbox" name="toggle-menu-item" id="toggleMisc" defaultChecked={details.visibility_options} />
                <input className="d-none" type="checkbox" name="toggle-menu-item" id="toggleLegend" defaultChecked={details.visibility_legend}/>

                <PageDefaultMap className="map"
                    details={details}
                    maxBounds={maxBounds}
                    mapLayers={mapLayers}
                    selectedLayer={selectedLayer}
                >
                    <ToggleOptions />

                    {details.introduction &&
                        <IntroTour
                            button_text={details.introduction_button}
                            autoplay={details.autoplay}
                            {...details.introduction}
                        />
                    }

                    {groups.length > 1 &&
                        <MapLayerContainer mapLayers={groups} selectedLayer={selectedGroup} maxBounds={maxBounds} setSelectedLayer={this.setSelectedGroup} />
                    }
                </PageDefaultMap>
            </div>
        );
    }
}

// withRouter should be first to keep connection with the base router
export default withPage(inject('MapLayerStore')(observer(StoryMapPage)));