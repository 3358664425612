import React from "react";
import { FormGroup, CustomInput, Col } from "reactstrap";
import PropTypes from "prop-types";

export default function ConditionCheckBox({ id, file }) {
    return <FormGroup row >
        <Col sm={{ size: 9, offset: 3 }}>
            <CustomInput
                id={'enquete_' + id + '_conditions'}
                defaultChecked={false}
                required={true}
                type="checkbox"
                name="conditions"
                value=""
                label="ik ga akkoord met de "
            >
                <a rel="noopener noreferrer" target="_blank" href={file}> voorwaarden</a>
            </CustomInput>
        </Col>
    </FormGroup>
}

ConditionCheckBox.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    file: PropTypes.string
}