import React from "react";
import PropTypes from "prop-types";
import { FormGroup, Input, CustomInput, FormText } from "reactstrap";
import wagtailToFieldTypes from "utils/wagtailToFieldTypes";
import EnqueteFormLabel from "./EnqueteFormLabel";

export default function EnqueteFormField({ id, question, field_type, label, name, regex_validation, choices, default_value, required, help_text, enqueteId }) {

    const choisesArray = choices.split(',');
    const defaultValueArray = default_value.split(',');
    const field_type_parsed = wagtailToFieldTypes[field_type];

    function getUniqueFormElementId(id) {
        return ('enquete_' + enqueteId + '_' + id);
    }

    switch (field_type) {
        case "number":
        case "singleline":
        case "url":
        case "datetime":
        case "date":
        case "email":
            return (
                <FormGroup >
                    <EnqueteFormLabel for={getUniqueFormElementId(id)} question={question} required={required} />
                    <Input
                        id={getUniqueFormElementId(id)}
                        required={required}
                        type={field_type_parsed}
                        name={name}
                        placeholder={label}
                        {...(regex_validation ? { pattern: regex_validation } : {})}
                        defaultValue={default_value} />
                    {help_text && <FormText>{help_text}</FormText>}
                </FormGroup>
            );
        case "dropdown":
        case "multiselect":
            return (

                <FormGroup >
                    <EnqueteFormLabel for={getUniqueFormElementId(id)} question={question} required={required} />
                    <select
                        id={getUniqueFormElementId(id)}
                        required={required}
                        className="form-control"
                        multiple={(field_type === 'multiselect')}
                        name={name}
                        defaultValue={(field_type === 'multiselect') ? defaultValueArray : defaultValueArray[0]}
                    >
                        {choisesArray.map((choise, index) => (<option key={index}
                        >{choise}</option>)
                        )}
                    </select>
                    {help_text && <FormText>{help_text}</FormText>}
                </FormGroup>
            );
        case "checkbox":
            return (
                <FormGroup >
                    <EnqueteFormLabel for={getUniqueFormElementId(id)} question={question} required={required} />
                    <CustomInput
                        id={getUniqueFormElementId(id) + '_' + id}
                        defaultChecked={!!default_value}
                        required={required}
                        type={field_type_parsed}
                        name={name}
                        value={label}
                        label={label}
                    />
                    {help_text && <FormText>{help_text}</FormText>}
                </FormGroup>
            );
        case "checkboxes":
        case "radio":
            return (
                <FormGroup >
                    <EnqueteFormLabel for={getUniqueFormElementId(id)} question={question} required={required} />
                    {choisesArray.map((choise, index) => (
                        <CustomInput
                            id={getUniqueFormElementId(id) + '_' + index}
                            key={index}
                            defaultChecked={(defaultValueArray.includes(choise))}
                            type={field_type_parsed}
                            name={name}
                            value={choise}
                            label={choise} />
                    ))}
                    {help_text && <FormText>{help_text}</FormText>}
                </FormGroup>
            );
        case "multiline":
            return (
                <FormGroup >
                    <EnqueteFormLabel for={getUniqueFormElementId(id)} question={question} required={required} />
                    <textarea
                        id={getUniqueFormElementId(id)}
                        required={required}
                        className="form-control"
                        name={name}
                        defaultValue={default_value}
                        placeholder={label} />
                    {help_text && <FormText>{help_text}</FormText>}
                </FormGroup>
            );
        case "hidden":
            return (
                <Input
                    id={getUniqueFormElementId(id)}
                    type="hidden"
                    name={name}
                    defaultValue={default_value} />
            );

        default:
            return null;
    }
}

EnqueteFormField.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    enqueteId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    question: PropTypes.string,
    field_type: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    regex_validation: PropTypes.string,
    choices: PropTypes.string,
    default_value: PropTypes.string,
    required: PropTypes.bool,
    help_text: PropTypes.string
}