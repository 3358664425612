import React from "react";
import PropTypes from "prop-types";
import { inject, observer } from "mobx-react";
import { LayersControl, LayerGroup, withLeaflet } from "react-leaflet";
import { geoJSON } from "leaflet";
import EsriMapLayer from "./EsriMapLayer/EsriMapLayer";
import ControlledLayers from "./ControlledLayers";
import ImageLayer from "./ImageLayer/ImageLayer";

const { Overlay } = LayersControl;

function mapLayer(layer, index) {
    switch (layer.meta.type) {
        case 'map.EsriMapLayer':
        case 'storymap.GroupEsriMapLayer':
            return <EsriMapLayer layer={layer} zIndex={index} />;
        case 'mer.MapContentBlock':
            return <LayerGroup>
                {layer.layerlist.map((layerComponent, index2) =>
                    <EsriMapLayer layer={layerComponent.value} key={index2} zIndex={index2} />
                )}
            </LayerGroup>;
        case 'map.ImageMapLayer':
        case 'storymap.GroupImageMapLayer':
            return <ImageLayer layer={layer} zIndex={index} />;
        default:
            return null;
    }
}

function isLayerSelected(layer, selectedLayer) {
    return layer.id === selectedLayer.id && layer.meta.type === selectedLayer.meta.type
}

function MapLayers({ MapLayerStore: { selectedLayer, mapLayers }, leaflet, ...props }) {
    if (selectedLayer.location) {
        const locationLayer = geoJSON(
            selectedLayer.location, {
            style: { stroke: 0, fill: 0 }
        }).addTo(leaflet.map);
        leaflet.map.flyToBounds(locationLayer.getBounds());
    }

    return <ControlledLayers>
        {mapLayers.map((layer, layerIndex) => (
            <Overlay key={layer.meta.type + layer.id} checked={isLayerSelected(layer, selectedLayer)} {...props}>
                {mapLayer(layer, layerIndex)}
            </Overlay>
        ))
        }
    </ControlledLayers >;
}

MapLayers.propTypes = {
    leaflet: PropTypes.shape({
        map: PropTypes.shape({
            flyToBounds: PropTypes.func
        })
    }),
    MapLayerStore: PropTypes.shape({
        selectedLayer: PropTypes.object,
        mapLayers: PropTypes.any
    })
};


export default withLeaflet(inject('MapLayerStore')(observer(MapLayers)));
