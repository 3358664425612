import React, { Component } from 'react';
import { Route, withRouter } from "react-router";

import PropTypes from "prop-types";
import { LatLngBounds } from 'leaflet';
import { inject, observer, PropTypes as MobxPropTypes } from "mobx-react";
import { coordsToLatLng } from "utils/geoHelpers";
import POILayer from 'components/POILayer/POILayer';
import withPage from "components/withPage/withPage";
import PageDefaultMap from "components/DefaultMap/PageDefaultMap";
import LayerText from "components/MapLayers/LayerText/LayerText";
import RespondOverlay from 'components/RespondOverlay/RespondOverlay';
import ResponseLayer from 'components/ResponseLayer/ResponseLayer';
import IntroTour from "components/IntroTour/IntroTour";
import RespondPartialController from 'scenes/RespondPartial/RespondPartialController';
import RespondPartial from 'scenes/RespondPartial/RespondPartial';

import MapLayerContainer from 'components/MapLayers/MapLayerContainer/MapLayerContainer';
import ToggleOptions from 'components/ToggleOptions/ToggleOptions';
import Loader from 'components/Loader/Loader';

/**
 * Map Page
 *
 * @description
 * Renders the map page from wagtail including all components
 */
export class MapPage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            disableMapElements: false
        };

        this.toggleMapElements = this.toggleMapElements.bind(this)
    }

    toggleMapElements(attribute) {
        this.setState({
            disableMapElements: attribute
        })
    }


    static propTypes = {
        /**
         * Page Store
         */
        PageStore: PropTypes.shape({
            /**
             * Map Page details
             */
            details: PropTypes.shape({
                upper_left: PropTypes.shape({
                    /**
                     * Coordinates of the map center
                     */
                    coordinates: PropTypes.object
                }),
                lower_right: PropTypes.shape({
                    /**
                     * Coordinates of the map center
                     */
                    coordinates: PropTypes.object
                }),
                image_map_layers: PropTypes.object,
                esri_map_layers: PropTypes.object,
                respond_form: PropTypes.object,
                base_layer: PropTypes.string,
                introduction: MobxPropTypes.observableObject,
                introduction_button: PropTypes.string,
                autoplay: PropTypes.bool
            })
        }),
        MapLayerStore: PropTypes.shape({
            setMapLayers: PropTypes.func,
            selectedLayer: PropTypes.object,
            setSelectedLayer: PropTypes.func,
            mapLayers: PropTypes.object
        }),
        /**
         * React router match prop
         */
        match: PropTypes.object,
        /**
         * React router history prop
         */
        history: PropTypes.object,
        meta: PropTypes.shape({
            type: PropTypes.oneOf(['map.MapPage', 'storymap.StoryMapPage']),
        })
    };

    componentDidMount() {
        const { MapLayerStore: { setMapLayers, setSelectedLayer }, PageStore: { details } } = this.props;
        // TODO check storymaplayer
        //line below is a bit ugly because we didnt manage to get the maplayers in one array from wagtail.
        const mapLayers = setMapLayers(details.image_map_layers.concat(details.esri_map_layers.slice()));
        setSelectedLayer(mapLayers.find(mapLayer => mapLayer.sort_order === 0));
    }

    setSelectedLayer = (layer) => {
        const { MapLayerStore: { setSelectedLayer }, match, history } = this.props;
        history.push(match.path);
        setSelectedLayer(layer);
    }

    render() {
        const { PageStore: { details }, MapLayerStore: { selectedLayer, mapLayers }, match, history } = this.props;

        const maxBounds = new LatLngBounds(coordsToLatLng(details.upper_left.coordinates), coordsToLatLng(details.lower_right.coordinates));
        return (
            selectedLayer.id ? <div className={" map-page " + (this.state.disableMapElements ? " map-response-active " : "")} id="text-overlay" data-layer={details.base_layer}>

                <input className="d-none" type="checkbox" name="toggle-menu-item" id="toggleLayers" defaultChecked={details.visibility_maplayers} />
                <input className="d-none" type="checkbox" name="toggle-menu-item" id="toggleMisc" defaultChecked={details.visibility_options} />
                <input className="d-none" type="checkbox" name="toggle-menu-item" id="toggleLegend" defaultChecked={details.visibility_legend}/>

                <LayerText />

                <PageDefaultMap className="map"
                    ref={this.map}
                    details={details}
                    maxBounds={maxBounds}
                    selectedLayer={selectedLayer}
                    history={history}
                    match={match}
                >
                    <ToggleOptions />

                    {details.introduction &&
                        <IntroTour
                            button_text={details.introduction_button}
                            autoplay={details.autoplay}
                            {...details.introduction}
                        />
                    }
                    {details.respond_form &&
                        <RespondOverlay toggleMapElements={this.toggleMapElements} respond_form={details.respond_form} base_layer={details.base_layer}
                            selectedLayer={selectedLayer} />
                    }
                    <ResponseLayer selectedLayerId={selectedLayer.id} history={history} match={match} />

                    <POILayer parentPage={match.path} />

                    {mapLayers.length > 1 &&
                        <MapLayerContainer mapLayers={mapLayers} selectedLayer={selectedLayer} maxBounds={maxBounds} setSelectedLayer={this.setSelectedLayer} />
                    }

                    <Route
                        path={`${match.path}/response/:respond_id`}
                        render={(props) => (
                            <RespondPartialController {...props} />
                        )}
                    />
                </PageDefaultMap>

                <RespondPartial parentPage={match.path} />
            </div> : <Loader />
        );
    }
}

// withRouter should be first to keep connection with the base router
export default withRouter(withPage(inject('MapLayerStore')(observer(MapPage))));