import React, { Fragment } from "react"
import { Waypoint } from "react-waypoint"
import Blocks from "components/Blocks/Blocks"
import PropTypes from "prop-types"

/**
 * WayPoint text element which implements the waypoint library to track scrolling position
 *
 * @export
 */
export default function WayPointText({ onEnterViewport, onLeaveViewport, node }) {
    return <Fragment>
        <Waypoint bottomOffset='60%' onEnter={() => onEnterViewport(node)} onLeave={(object) => onLeaveViewport(object, node)}>
            <div className="storymap-chapter">
                <div name={`text${node.id}`} />
                <Blocks blocks={node.text} disableLazyload={true} />
            </div>
        </Waypoint>
        <div className="mobile-spacer"></div>
    </Fragment>
}

WayPointText.propTypes = {
    /**
     * Function that gets called if element enters the viewport
     */
    onEnterViewport: PropTypes.func,
    /**
     * Function that gets called if element leaves the viewport
     */
    onLeaveViewport: PropTypes.func,
    /**
     * Current MER tree node
     */
    node: PropTypes.object
}