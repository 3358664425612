import SVGIcons from 'components/svgIcons/svgIcons';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';

export default function RespondButton(props) {
    return <Button data-test="btn-respond" color="primary" className="btn-no-icon text-nowrap text-center" {...props}>
        <SVGIcons className=" d-block mx-auto" name="bubble" width={32} height={32} fill="currentColor" />
        <span>{props.children}</span>
    </Button>
}

RespondButton.propTypes = {
    children: PropTypes.string
}