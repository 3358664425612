import React, { Component } from 'react';
import PropTypes from "prop-types"
import { inject, observer } from "mobx-react";
import { Router } from "react-router-dom";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";
import { azure_instrumentation_key, theme } from 'utils/envHelpers';
import Routes from "./routes/Routes";
import Header from "./components/Header/Header";
import Main from "./components/Main/Main";
import Offline from "./components/Offline/Offline";

Promise.all([
    import('./css/themes/' + theme + '/scss/index.scss')
]);

export const history = createBrowserHistory({ basename: '' })

/**
 * Renders Routes component and passes Header as children
 */
class App extends Component {
    static propTypes = {
        /**
         * Page data coming from the injected MobX Page store
         */
        PageStore: PropTypes.shape({
            /**
             * Array of page routes
             */
            routes: PropTypes.object.isRequired,
            /**
             * Loading state
             */
            loading: PropTypes.bool,
            getPathWithParent: PropTypes.func
        }),
        CommonStore: PropTypes.shape({
            token: PropTypes.string
        }),
        userStore: PropTypes.shape({
            pullUser: PropTypes.func
        })
    };

    componentDidMount() {
        if (this.props.CommonStore.token) {
            this.props.userStore.pullUser()
        }
    }

    render() {
        const { PageStore: { loading, routes, getPathWithParent } } = this.props;
        return (
            <Main data-testid="app">
                <Router history={history}>
                    <Routes
                        loading={loading}
                        routes={routes}
                        getPathWithParent={getPathWithParent}
                    >
                        <Header routes={routes} />

                        <Offline />

                    </Routes>
                </Router>
            </Main>
        );
    }
};

let app = inject("PageStore", "CommonStore", "userStore")(observer(App))

if (azure_instrumentation_key) {
    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: azure_instrumentation_key,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history: history }
            }
        }
    });
    appInsights.loadAppInsights();
    app = withAITracking(reactPlugin, app, "div", "azure-instrumentation-div")
}

export default app
