import React from "react";
import {Marker} from "react-leaflet";
import MapIcon from "../../MapIcon/MapIcon";
import SVGIcons from "../../svgIcons/svgIcons";


const RespondMarker = props => {

    const initMarker = ref => {
        if (ref) {
            ref.leafletElement.openPopup()
        }
    };

    return <Marker icon={MapIcon(<SVGIcons className="map-response-icon map-response-default" name="bubble" width={40} height={40} fill="currentColor"/>)} ref={initMarker} {...props}/>
};

export default RespondMarker;