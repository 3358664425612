import React, { Component } from 'react';
import { LatLngBounds } from 'leaflet';
import PropTypes from "prop-types";
import { inject, observer, PropTypes as MobxPropTypes } from "mobx-react";
import { coordsToLatLng } from "utils/geoHelpers";
import withPage from "components/withPage/withPage";
import PageDefaultMap from "components/DefaultMap/PageDefaultMap";
import IntroTour from "components/IntroTour/IntroTour";
import MerTree from 'components/Mer/MerTree';
import MerContent from 'components/Mer/MerContent';
import Control from "react-leaflet-control";
import { Button } from 'reactstrap';
import { faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ToggleOptions from 'components/ToggleOptions/ToggleOptions';
import MerRoutes from 'components/Mer/MerRoutes';
import EffectMenu from 'components/Mer/EffectMenu/EffectMenu';
import slugify from 'slugify';
import FeatureLayer from 'components/MapLayers/FeatureLayer/FeatureLayer';
import { withRouter } from 'react-router';
import MapLayerContainer from 'components/MapLayers/MapLayerContainer/MapLayerContainer';



/**
 * Mer Page
 *
 * @description
 * Renders the MER page from wagtail including all components
 */
class MerPage extends Component {


    static propTypes = {
        id: PropTypes.number,
        /**
         * React router match prop
         */
        match: PropTypes.object,
        /**
         * React router history prop
         */
        history: PropTypes.object,
        location: PropTypes.object,
        meta: PropTypes.shape({
            type: PropTypes.string,
        }),
        /**
         * Page Store
         */
        PageStore: PropTypes.shape({
            /**
             * Map Page details
             */
            details: PropTypes.shape({
                introduction: MobxPropTypes.observableObject,
                introduction_button: PropTypes.string,
                autoplay: PropTypes.bool,
                visibility_maplayers: PropTypes.bool,
                visibility_options: PropTypes.bool,
                visibility_legend: PropTypes.bool,
                /**
                 * Map center
                 */
                upper_left: PropTypes.shape({
                    /**
                     * Coordinates of the map center
                     */
                    coordinates: PropTypes.object
                }),
                lower_right: PropTypes.shape({
                    /**
                     * Coordinates of the map center
                     */
                    coordinates: PropTypes.object
                }),
                meta: PropTypes.shape({
                    slug: PropTypes.string,
                    type: PropTypes.string
                }),
                feature_layer: PropTypes.object
            })
        }),
        MerStore: PropTypes.shape({
            setBaseRoute: PropTypes.func,
            getAllNodes: PropTypes.func,
            getAllNodesAndReturnMapLayers: PropTypes.func,
            nodesTree: MobxPropTypes.observableObject,
            activeContent: MobxPropTypes.observableObject,
            activeEffectTree: PropTypes.object
        }),
        MapLayerStore: PropTypes.shape({
            mapLayers: MobxPropTypes.observableArray,
            layersToToggle: MobxPropTypes.observableArray,
            selectedLayer: MobxPropTypes.observableObject,
            setMapLayers: PropTypes.func,
            setSelectedLayer: PropTypes.func
        })
    };

    state = {
        disableMapElements: false,
        featureLink: ""
    };

    timer;

    toggleMapElements = (attribute) => {
        this.setState({
            disableMapElements: attribute
        })
    }


    componentDidMount() {
        const { id, MapLayerStore: { setMapLayers }, MerStore: { getAllNodesAndReturnMapLayers, setBaseRoute } } = this.props;
        // TODO check storymaplayer
        getAllNodesAndReturnMapLayers(id, this.props.PageStore.details.meta.type === 'mer.MapMerPage').then(mapLayers => {
            mapLayers && setMapLayers(mapLayers);
        });
        setBaseRoute(this.props.PageStore.details.meta.slug)
    }

    onFeatureClick = (feature) => {
        const { PageStore: { details }, history, location } = this.props;

        const featureSlug = slugify(feature.properties[details.feature_layer[0].interactive_column], { lower: true });
        if (location.pathname.match(new RegExp(`/\/${featureSlug}/`, 'g'))) return;

        const link = `/${details.meta.slug}/${featureSlug}`;
        this.setState({ featureLink: link })
        // Wait for popup to open, else continue link
        this.timer = setTimeout(function () { history.push(link) }, 500);
    }

    disableFeatureClick = () => {
        // if popup is opened cancel the route change
        clearTimeout(this.timer)
    }

    render() {
        const { PageStore: { details }, MerStore: { nodesTree, activeContent, activeEffectTree }, MapLayerStore: { setSelectedLayer, mapLayers, selectedLayer, layersToToggle } } = this.props;

        const maxBounds = new LatLngBounds(coordsToLatLng(details.upper_left.coordinates), coordsToLatLng(details.lower_right.coordinates));

        let legendlayers = [];
        if (mapLayers.length && selectedLayer.meta && selectedLayer.meta.type === 'mer.MapContentBlock') {
            legendlayers = selectedLayer.layerlist.filter(el => el.value.showLegend).map(layer => layer.value);
        }

        return (
            <div className={" map-page map-mer-page " + (this.state.disableMapElements ? " map-response-active " : "")}>
                <input className="d-none" type="checkbox" name="toggle-menu-item" id="toggleLayers" defaultChecked={details.visibility_maplayers} />
                <input className="d-none" type="checkbox" name="toggle-menu-item" id="toggleMisc" defaultChecked={details.visibility_options} />
                <input className="d-none" type="checkbox" name="toggle-menu-item" id="toggleLegend" defaultChecked={details.visibility_legend} />

                {details.meta.type === 'mer.ReportMerPage' ? <MerTree nodes={nodesTree.items} /> : <MerRoutes baseRoute={details.meta.slug} nodes={nodesTree.items} />}

                <MerContent content={activeContent} />

                <PageDefaultMap className="map"
                    details={details}
                    maxBounds={maxBounds}
                    mapLayers={legendlayers}
                    selectedLayer={selectedLayer}
                    onPopupOpen={this.disableFeatureClick}

                >
                    <ToggleOptions />

                    {activeEffectTree.id && <EffectMenu baseRoute={details.meta.slug} node={activeEffectTree} />}

                    <Control position="bottomleft" className="d-flex align-items-stretch btn-group order-first map-mer-toggleview">
                        <Button tag="label" htmlFor="mer-toggleview" color="primary" className="mb-0 btn-no-icon text-nowrap text-center">
                            <FontAwesomeIcon size="2x" className=" d-block mx-auto" icon={faList} />
                            <span>Bekijk text</span>
                        </Button>
                    </Control>


                    {layersToToggle.length > 1 &&
                        <MapLayerContainer mapLayers={layersToToggle} selectedLayer={selectedLayer} maxBounds={maxBounds} setSelectedLayer={setSelectedLayer} />
                    }

                    {details.introduction &&

                        <IntroTour
                            button_text={details.introduction_button}
                            autoplay={details.autoplay}
                            {...details.introduction}
                        />
                    }
                    {details.feature_layer && details.feature_layer.length > 0 && <FeatureLayer pane="tilePane" layer={details.feature_layer[0]} onFeatureClick={this.onFeatureClick} />}
                </PageDefaultMap>
            </div>
        );
    }
}

export default withRouter(withPage(inject('PageStore', 'MerStore', 'MapLayerStore')(observer(MerPage))));