import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Alert} from "reactstrap";


const useMapMousePosition = (map) => {
    const [position, setPosition] = useState({top: 0, left: 0});
    const margin = 10;

    useEffect(() => {
        const setFromEvent = e => setPosition({left: e.containerPoint.x + margin, top: e.containerPoint.y + margin});
        map.on("mousemove", setFromEvent);

        return () => {
            map.off("mousemove");
        };
    }, [map]);

    return position;
};

function CursorLabel({map}) {
    const position = useMapMousePosition(map);
    return position && <Alert color="info" style={position} className="cursor-label">Klik op de kaart om een reactie te plaatsen</Alert>;
}

CursorLabel.propTypes = {
    map: PropTypes.object
};

export default CursorLabel;