import { observable, action, decorate, runInAction } from "mobx"
import { latLngToCoords } from "leaflet/src/layer/GeoJSON";
import api from "services/api";

const defaultResponse = {
    respondform: null,
    mood: null,
    theme: {
        value: ""
    },
    involvement: {
        value: ""
    },
    text: "",
    name: "",
    email: "",
    photo: null,
    privacy: false,
    sendmail: false,
    zip_code: "",
    position: null,
    poi: null,
    page: null,
    layer_type: null,
    layer_id: null,
};

/**
 * Mobx store to load and control page content
 */
export class ResponseStore {
    /**
     * Response store loading state
     * @type {boolean}
     */
    loading = false;
    /**
     * Array of all response objects
     * @type {Array}
     */
    responses = [];
    /**
     * Error message
     * @type {Object}
     */
    error = {};
    /**
     * Success
     * @type {boolean}
     */
    success = false;
    /**
     * Responding state
     * @type {boolean}
     */
    isResponding = false;
    /**
     * Selected response on the map
     * @type {Object}
     */
    selectedResponse = {};
    /**
     * Response object
     * @type {{involvement: {value: null}, mood: null, name: string, privacy: boolean, theme: {value: null}, text: string, position: null, poi: null, email: string, zip_code: string}}
     */
    response = defaultResponse;

    constructor() {
        this.api = api;
    }

    /**
     * Get all responses from the database
     * @return {Promise<T | never>}
     */
    getAllResponses = async () => {
        this.loading = true;
        try {
            let responses = await this.api.Response.all();

            runInAction(() => {
                this.loading = false;
                this.responses = responses;
            });
        } catch (error) {
            runInAction(() => {
                this.loading = false;
                this.error = error;
            })
        }
    };

    /**
     * Sets the selectedResponse based on the id or clears if the id is empty
     * @param id String
     * @return {Promise<{}>}
     */
    setSelectedResponseByID = async (id) => {
        // clear selected response
        if (!id) return this.selectedResponse = {};

        !this.responses.length && await this.getAllResponses();
        return runInAction(() => {
            return this.selectedResponse = this.responses.find((response) => response.id === parseInt(id)) || {};
        })
    };

    /**
     * Adds a new response
     * @return {Promise<T | never>}
     */
    addResponse = async () => {
        this.response.location = this.response.position && {
            type: 'Point',
            coordinates: latLngToCoords(this.response.position)
        };
        this.loading = true;
        this.success = false;


        var form_data = new FormData();

        for (var key in this.response) {
            if (key === 'theme' || key === 'involvement') {
                form_data.append(key + '.value', this.response[key].value);
            }
            else if (key === 'location' && this.response[key]) {
                form_data.append(key, JSON.stringify(this.response[key]))
            }
            else {
                form_data.append(key, this.response[key] || "");
            }
        }

        try {
            let response = await this.api.Response.add(form_data);

            runInAction(() => {
                this.success = true;
                this.loading = false;
                this.error = {};
                this.response = defaultResponse;
                this.getAllResponses();
            });

            return response;

        } catch (error) {
            runInAction(() => {
                this.loading = false;
                this.error = error.response.data;
            });
        }
    };

    unsubscribe = async (token) => {
        this.loading = true;
        this.success = false;
        try {
            let response = await this.api.Response.unsubscribe(token);

            runInAction(() => {
                this.success = true;
                this.loading = false;
            })

            return response;
        } catch (error) {
            runInAction(() => {
                this.loading = false;
                this.error = error.response.data;
            });
        }
    }

    /**
     * Clears the responding state except position
     */
    clearResponse = () => {
        this.response = {
            ...defaultResponse,
            position: this.response.position,
        };
        this.error = {};
    };

    /**
     * Toggles the responding state
     */
    toggleResponding = () => {
        this.isResponding = !this.isResponding;
    };

    /**
     * Sets the response position if responding on a map
     * @param latlng
     */
    setResponsePosition = (latlng) => {
        this.response.position = latlng;
    };

    /**
     * Sets the corresponding respond form id on the response
     * @param {object} respondform
     */
    setRespondForm = (respondform) => {
        this.response.respondform = respondform.id;
    }

    /**
     * Sets the details of a poi if responding on a poi
     * @param poi_id Number
     * @param latlng Array
     */
    setResponsePoi = (poi_id, latlng) => {
        this.response.page = null;
        this.response.layer_type = null;
        this.response.layer_id = null;
        this.response.poi = poi_id;
        this.setResponsePosition(latlng);
    }

    /**
     * Sets de details of a page if responding on a page
     * @param page_id Number
     */
    setResponsePage = (page_id) => {
        this.response.page = page_id;
    }

    setResponseLayer = (mapLayer) => {
        this.response.layer_type = mapLayer.meta.type === 'map.EsriMapLayer' ? 'esrimaplayer' : 'imagemaplayer';
        this.response.layer_id = mapLayer.id;
    };

    /**
     * Sets key on the response object with the given value
     * @param key String
     * @param value Any
     */
    updateProperty = (key, value) => {
        this.response[key] = value;
    };
}


decorate(ResponseStore, {
    loading: observable,
    responses: observable,
    error: observable,
    isResponding: observable,
    response: observable,
    selectedResponse: observable,

    getAllResponses: action,
    setSelectedResponseByID: action,
    addResponse: action,
    clearResponse: action,
    toggleResponding: action,
    setResponsePosition: action,
    setResponsePoi: action,
    setRespondForm: action,
    setResponsePage: action,
    updateProperty: action,
    unsubscribe: action
});


export default new ResponseStore();